import { html } from "lit";
import { ClientService } from "../client-service/client-service";
import { ModalLayover } from "../components/modal-layover";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { GameAchievementService } from "../achievements/game-achievement-service";
import { ClientDispatcherService } from "../client-dispatcher-service/client-dispatcher-service";
import { ToastService } from "../toast-service/toast-service";
import { ConsoleInterceptor } from "../console-logger/console-logger";
import { UserEventsServiceComposite } from "../user-events/user-events-service-composite";
import { tutorialRequestedEvent } from "../user-events/events/tutorial-requested-event";

/** Handle themes for the header element */
const clientService = new ClientService();
const storageService = new LocalStorageService();
const userEventsService = new UserEventsServiceComposite(
    clientService,
    new LocalStorageService()
);

document.addEventListener('DOMContentLoaded', () => {
    const settingsEl = document.getElementById('settings-container')!
    let longPressTimeout: ReturnType<typeof setTimeout>;

    const startLongPress = () => {
        longPressTimeout = setTimeout(showModal, 5_000);
    };

    const cancelLongPress = () => {
        clearTimeout(longPressTimeout);
    };

    settingsEl.addEventListener("click", () => {
        console.log("[ListenThemeClick-script] mouse clickecd");
        navigateToSettings();
        event?.preventDefault();
    });

    // Mouse events
    settingsEl.addEventListener("mousedown", startLongPress);
    settingsEl.addEventListener("mouseup", cancelLongPress);
    settingsEl.addEventListener("mouseleave", cancelLongPress);

    // Touch events
    settingsEl.addEventListener("touchstart", startLongPress);
    settingsEl.addEventListener("touchend", cancelLongPress);
    settingsEl.addEventListener("touchcancel", cancelLongPress);

    // tutorial
    document.getElementById('tutorial-menu')!.addEventListener('click', async (e) => {

        await userEventsService.logEvent(
            tutorialRequestedEvent(void 0)
        );

        await storageService.set("tutorial-in-progress", {
            is: true
        });
        const url = clientService.createLocalLink(
            "app",
            {},
            true
        );
        window.location.href = url
    }, true);
});

async function showModal(): Promise<void> {
    const clientService = new ClientService();
    const achievementService = new GameAchievementService(
        new ClientService(),
        new LocalStorageService(),
        new ToastService(),
        new ClientDispatcherService(
            new ClientService()
        )
    );

    const contents = {
        config: clientService.getConfig(),
        achievements: await achievementService.getDebugInfo(),
        // TODO: this is a singleton; but hacky way to do it
        logs: new ConsoleInterceptor(console).getLogs()
    };

    const configJSON = JSON.stringify(contents, null, 4);
    const modal = new ModalLayover();
    modal.title = "Technical Specifications";
    modal.classList.add('left-content')
    modal.contentTemplate = html`
<pre style="width: 100%">
<code>
${configJSON}
</code>
</pre>
    `;
    modal.open = true;

    document.body.appendChild(modal);
}


/**
 * TODO: move this into its own component?
 */
async function navigateToSettings(): Promise<void> {
    const url = clientService.createLocalLink(
        "account",
        {},
        true
    );
    window.location.href = url;
}