import { ClientDispatcherReadyResolver } from "../client-dispatcher-ready-resolver";

export class AndroidClientDispatcherReadyResolver extends ClientDispatcherReadyResolver {

    protected async doWaitForReady(): Promise<void> {
        console.log("[AndroidClientDispatcherReadyResolver] initializing");
        
        return new Promise((resolve) => {
            let eventReceived = false;
            window.addEventListener("client-app-ready", () => {
                eventReceived = true;
                resolve();
            });

            const startTimeout = () => {
                setTimeout(() => {
                    if (!eventReceived) {
                        console.log("[ClientDispatcherService] timeout reached, resolving anyway");
                        resolve();
                    }
                }, 1_000);
            }

            if (document.readyState === "loading") {
                document.addEventListener('DOMContentLoaded', () => {
                    startTimeout();
                });
            } else {
                startTimeout();
            }
        });
    }
}