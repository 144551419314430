import { ClientService } from "../client-service/client-service";
import { AndroidClientDispatcherReadyResolver } from "./resolver/android-client-dispatcher-ready-resolver";
import { IosClientDispatcherReadyResolver } from "./resolver/ios-client-dispatcher-ready-resolver";

export class ClientDispatcherService {

    private static instance: ClientDispatcherService | null = null;

    // we want to hold requests until we receive the initialization event
    private initPromise!: Promise<void>;

    constructor(
        private clientService: ClientService
    ) {
        //hacky way of enforcing singleton
        if (ClientDispatcherService.instance) {
            return ClientDispatcherService.instance;
        }

        ClientDispatcherService.instance = this;

        switch (this.clientService.getConfig().clientDispatcher.readyResolveStrategy) {
            case 'android':
                this.initPromise = new AndroidClientDispatcherReadyResolver().waitForReady();
                break;
            case 'ios':
                this.initPromise = new IosClientDispatcherReadyResolver().waitForReady();
                break;
            default:
                this.initPromise = Promise.resolve();
        }

        this.initPromise.then(() => console.log(`[ClientDispatcherService] web and app are ready to communicate with eachother`))
    }

    /**
     * Fire and forget
     */
    async dispatch<T>(eventName: string, eventData: T): Promise<void> {
        await this.initPromise;
        console.log("[ClientDispatcherService] dispatching", { eventName, eventData });
        window.dispatchEvent(new CustomEvent(eventName, {
            detail: eventData
        }));
    }

    /**
     * Fire a request and await a response
     * 
     * This class handles the following automatically:
     * Event name convention for request is `${eventName}-request`
     * Event name convention for response is `${eventName}-response`
     */
    async request<T, R>(
        eventName: string,
        eventData?: T,
        timeout = 3_000,
        retryCount = 1
    ): Promise<R> {
        await this.initPromise;
        let lastErr: any = null;
        for (let i = 0; i < retryCount; i++) {
            try {
                return await this.tryRequest(eventName, eventData, timeout);
            } catch (err) {
                lastErr = err;
            }
        }

        throw lastErr;
    }

    private tryRequest<T, R>(
        eventName: string,
        eventData?: T,
        timeout = 3_000,
    ): Promise<R> {
        const requestEvent = `${eventName}-request`;
        const responseEvent = `${eventName}-response`;

        console.log("[ClientDispatcherService] requesting", { requestEvent, eventData });

        return new Promise((resolve, reject) => {
            let dataReceived = false;

            window.addEventListener(responseEvent, (event: any) => {
                console.log("[ClientDispatcherService] response received", { responseEvent, detail: event?.detail });

                dataReceived = true;

                const { data, error } = event?.detail || {
                    error: `We didn't receive any data from ${responseEvent}`
                };

                if (error) {
                    reject(error);
                }

                resolve(data);
            }, { once: true });

            window.dispatchEvent(new CustomEvent(requestEvent, {
                detail: eventData
            }));

            // if nothing, 
            setTimeout(() => {
                if (!dataReceived) {
                    reject("We couldn't retrieve your data in a timely manner.");
                }
            }, timeout);
        });
    }
}