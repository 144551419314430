import { ClientDispatcherReadyResolver } from "../client-dispatcher-ready-resolver";

export class IosClientDispatcherReadyResolver extends ClientDispatcherReadyResolver {

    protected doWaitForReady(): Promise<void> {
        console.log("[IosClientDispatcherReadyResolver] initializing");

        return new Promise(resolve => {
            // for iOS only, use a double-dispatch ready check
            window.addEventListener('ios-app-ready', (event: any) => {
                console.log("[IosClientDispatcherReadyResolver] ios-app-ready event received", event.detail);
                if (!event.detail.iosWebReady || !event.detail.iosAppReady) {
                    const detail = {
                        iosWebReady: true,
                        iosAppReady: event.detail.iosWebReady
                    };
                    console.log("[IosClientDispatcherReadyResolver] dispatching ios-web-ready", detail);
                    window.dispatchEvent(new CustomEvent('ios-web-ready', {
                        detail
                    }))
                } else {
                    resolve();
                }
            });
            console.log("[IosClientDispatcherReadyResolver] dispatching initial ios-web-ready");
            window.dispatchEvent(new CustomEvent('ios-web-ready', {
                detail: {
                    iosWebReady: false,
                    iosAppReady: false
                }
            }));
        });
    }

}